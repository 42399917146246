
<template>
        <div class="container h-100 w-100 d-flex flex-column">
                <div class="file-header mb-auto">
                    <h4 class="text-center">
                       {{ file.name }}
                    </h4>
                </div>
                
                <div class="file-body" style="width:100%; height:100%; max-height:90%;">

                    <div id="pdf-viewer" style='width: 100%; height: 90%; margin: 0 auto; ' v-if="getType == 'pdf'" >  
                    </div>
                    <div v-if="getType == 'link'" class="w-100 h-100 d-flex justify-content-center align-items-center flex-column"  >
                        <h3>Click here to visit</h3>
                        <a :href="file.name"  class="text-center"  target="new_tab()"> <p style="overflow:hidden; white-space: nowrap; text-overflow:ellipsis; width:300px; color:black !important;"> {{ file.name }}</p></a>
                    </div>
                    <div v-if="getType != 'link' && getType != 'pdf'" class="d-flex justify-content-center align-items-center" style="width: 100%; height: 90%" >
                            <img :src="getBinary" style="max-width: 90%; max-height: 90%;" >
                    </div>
  
                </div>
        
                <div class="file-footer d-flex mt-auto">
                    <span  v-if="getType != 'link'">
                        <button class="btn btn-primary " @click="downloadFile">
                            <i class="fa-solid fa-cloud-arrow-down"></i>
                        </button>
                    </span>
                     <span class="ml-auto">
                        <button class="btn btn-secondary" @click="closeFile">
                            Close
                        </button>
                    </span>
                </div>
        </div>
</template>
<script>
import WebViewer from '@pdftron/webviewer'

export default {
    inject : ['closeFile'],
    props: ['files'],
    data(){
        return{
            file :[],
            fileType : ''
        }
    },
    computed : {
        getBinary() {
            return (this.file.hasOwnProperty.call(this.file, 'binary') ? 'data:'+(this.file.type == 'pdf' ? 'application/pdf' : 'image/png')+';base64,'+this.file.binary : '');
        },
        getType() {
            return (this.file.hasOwnProperty.call(this.file, 'type') ? this.file.type : '');
        },
    },
    beforeMount(){  

        this.fileType = this.files.type
        this.file = this.files
        // console.log(this.files)
    },
    mounted() {
        // console.log(this.file)

    },
    unmounted(){
        this.fileType = ''
        // console.log('Unmounted')
    },
    methods: {
        convertPDF() {
            const binaryString = window.atob(this.file.binary);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; ++i) {
                bytes[i] = binaryString.charCodeAt(i);
            }

            return new Blob([bytes], { type: 'application/pdf' });
        },
        initPDF() {
            setTimeout(() => {
                const element = document.getElementById('pdf-viewer');
        
                WebViewer({
                path: `${process.env.BASE_URL}webviewer`, // point to where the files you copied are served from
                disabledElements: ['toolsHeader', 'ribbons'],
                fullAPI: true,
                }, element).then((instance) => {
                    const { docViewer } = instance;


                    instance.loadDocument(this.convertPDF(), { filename: `${this.file.name}.pdf` });

                    docViewer.on('documentLoaded', () => {
                        console.log('loaded')
                        // console.log(this.preview.binary)
                    });
                })

            }, 200);

       
        },

         async downloadFile() {
     
       
            var binary = 'data:'+this.file.mime_type+';base64,'+this.file.binary;
            const link = document.createElement('a');
            link.href = binary;
            link.setAttribute('download', this.file.name); //or any other extension
            document.body.appendChild(link);
            link.click();
            

        }
    },
    watch : {
        'fileType' :function(value){
            // console.log(value)
            if(value == 'pdf'){
               this.initPDF()
            }
        }
    }
}
</script>

<style scoped>
.file-footer {
    border-top: solid grey 2px;
    padding-top: 5px ;
    padding-bottom: 5px ;
    margin-bottom: 10px;
}
.file-header {
    border-bottom: solid grey 2px ;
    padding-top: 5px;
}
</style>