<template>
         
            <div class="p-3 d-flex flex-direction-row align-items-center" style="border-bottom: 1px solid rgba(57, 81, 155, .2);">
                <a href="javascript:void(0);" class="right-bar-toggle p-0 opacity-5" style="font-size: 2.5em; line-height: 0; color: #000;">&times;</a>
            </div>
            <div class="position-relative overflow-auto" >
                <div class="d-flex w-100 bg-light p-3">
                    <div class="align-items-center" >
                       <h3>CRF <span style="color:red;">{{selected_data.cr_no}} </span></h3>
                     
                    </div>

                    <div style="margin-left:auto;">
                          <!-- <h4 >{{selected_data.cr_date}}</h4> -->
                          <span :class="(status == 'approval' ? 'badge badge-pill badge-success' : status == 'posting' ? 'badge  badge-pill badge-warning' : 'badge badge-pill badge-primary' )">{{status}}</span>
                    </div>
                </div>
                <div class="d-flex flex-column mt-2 p-3">
                    <div class="d-flex w-100 justify-content-between" >
                         <div class="d-flex flex-column w-50">
                            <span class="mb-2">Payee</span>
                            <h4>{{selected_data.payee}}</h4>
                        </div>
                         <div class="d-flex flex-column  w-50">
                            <span class="mb-2">MIAF</span>
                            <h4>{{selected_data.miv_no == 0 ? '--' :  selected_data.miv_no}}</h4>
                        </div>
                    </div>
                    <div class="d-flex mt-2 w-100" >
                         <div class="d-flex flex-column w-50">
                            <span class="mb-2">Department</span>
                            <h4>{{selected_data.cr_dept}}</h4>
                        </div>
                         <div class="d-flex flex-column  w-50">
                            <span class="mb-2">Amount Requested</span>
                            <h4>{{selected_data.currency}} - {{selected_data.amount_requested}}</h4>
                        </div>
                    </div>
                    <div class="d-flex mt-2 w-100">
                         <div class="d-flex flex-column w-50">
                            <span class="mb-2">Date Needed</span>
                            <h4>{{selected_data.date_needed}}</h4>
                        </div>
                         <div class="d-flex flex-column  w-50">
                            <span class="mb-2">Requestor</span>
                            <h4>{{selected_data.requestor}}</h4>
                        </div>
                    </div>
                     <div class="d-flex mt-2 w-100">
                         <div class="d-flex flex-column w-50">
                            <span class="mb-2">JO</span>
                            <h4>{{selected_data.jo_number ? selected_data.jo_number : '--'}}</h4>
                        </div>
                         <div class="d-flex flex-column  w-50">
                            <span class="mb-2">PA</span>
                            <h4>{{selected_data.pa_number ? selected_data.pa_number : '--'}}</h4>
                        </div>
                    </div>
                    <div class="d-flex mt-2 w-100">
                         <div class="d-flex flex-column w-50">
                            <span class="mb-2">DN</span>
                            <h4>{{selected_data.dn_number ? selected_data.dn_number : '--'}}</h4>
                        </div>
                    
                    </div>
                    <div class="d-flex mt-2 w-100">
                        <div class="d-flex flex-column w-100">
                            <span class="mb-2">Particulars</span>
                            <h4>{{selected_data.particulars}}</h4>
                        </div>
                    </div>
                    <div class="d-flex mt-2 w-100" v-if="selected_data.dpv_reason && status == 'active'">
                        <div class="d-flex flex-column w-100">
                            <span class="mb-2" style="color:red;">Disapproved Reason</span>
                            <h4>{{selected_data.dpv_reason}}</h4>
                        </div>
                    </div>
                       <div class="d-flex flex-column  mt-2">
                      
                            <span class="mb-2">PA & DN Approved By</span>
                            <h4>{{selected_data.padn_approved ? selected_data.padn_approved : '--'}}</h4>
                       
                    </div>
                    <div class="d-flex flex-column  mt-2">
                      
                            <span class="mb-2">JO Approved By</span>
                            <h4>{{selected_data.jo_approved ? selected_data.jo_approved : '--'}}</h4>
                       
                    </div>
                    <div class="d-flex flex-column  mt-2">
                      
                            <span class="mb-2">Approved By</span>
                            <h4>{{selected_data.approved_by ? selected_data.approved_by : '--'}}</h4>
                       
                    </div>
                    <div class="d-flex flex-column  mt-2">
                      
                            <span class="mb-2">Posted By</span>
                            <h4>{{selected_data.posted_by ? selected_data.posted_by : '--'}}</h4>
                       
                    </div>
           
                    <div class="d-flex flex-column  mt-2  " v-if="files" style="border-top:solid grey 2px; padding-top:5px;">
                        <div class="d-flex justify-content-between">
                        <strong class="mb-2">Files</strong>

                        
                        </div>
                        <ul class="list-group list-group-flush  overflow-auto" style="height:250px;">
                            <li class="list-group-item list-group-item-action list-group-item-info" v-for="(file,index) in files " :key="index" @click="showFile(file)"> {{ file.name }}</li>
                        
                        </ul>
                    </div>
                    

                     <div class="d-flex flex-column  mt-2" v-if="disapproveShow">
                            <span class="mb-2">Disapprove Reason</span>

                            <textarea name="Text1" cols="10" rows="5" class="form-control" placeholder="Input Reason" v-model="data.reason"></textarea>
                       
                    </div>
                </div>
                
            </div>

           
            <div class="p-1 d-flex flex-direction-row align-items-center " style="border-top: 1px solid rgba(57, 81, 155, .2); overflow-y: auto; height: 100px;">
                <!-- <div class="btn-group dropup mr-1" role="group"  v-if="status=='active' && isAdmin != true" >
                    <button type="button" class="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <i class="fa-solid fa-caret-up mr-3"></i>
                        Actions
                    </button>
                    <div class="dropdown-menu" >
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item d-flex dp-items"  @click="Send(selected_data)">
                                <div class="mr-auto">
                                    Send For Approval
                                </div>
                                <i class="fa-sharp fa-solid fa-paper-plane "></i>
                            </li>
                            <li class="list-group-item d-flex dp-items" @click="Edit(selected_data)">
                                <div class="mr-auto">
                                    Edit
                                </div>
                                <i class="fa-solid fa-pen-to-square"></i>
                            </li>
                        </ul>
                    </div>
                </div> -->
                <span v-if="status=='active' && isAdmin != true" class="d-flex">
                    <span class="mr-1">
                        <button class="btn btn-success btn-sm" @click="Send(selected_data)" v-if="!send_loading" :disabled="true">
                            <i class="fa-sharp fa-solid fa-paper-plane "></i>
                                Send
                        </button>
                        
                        <button class="btn btn-success btn-sm " v-else>
                            <span class="spinner-border spinner-border-sm"></span> 
                        </button>
                    </span>
                    <span class="mr-1">
                        <button class="btn btn-info btn-sm "  @click="Edit(selected_data)" >
                            <i class="fa-solid fa-pen-to-square"></i>
                                Edit
                        </button>
                        

                    </span>

                    <span class="mr-1">
                        <button class="btn btn-primary btn-sm " @click="showUpload" v-if="!isuploadLoading">
                            <i class="fa-solid fa-cloud-arrow-up" ></i>
                                Upload
                        </button>
                        
                        <button class="btn btn-primary btn-sm " v-else>
                            <span class="spinner-border spinner-border-sm"></span> 
                        </button>
                    </span>

                </span>

                <span class="mr-2" v-if="status=='posting' || status == 'approval' && me.crf_users == 'CRT'">
                        <button class="btn btn-danger btn-sm"  @click="reverseCRF(selected_data)" v-if="!reverse_loading">
                            <i class="fa-solid fa-clock-rotate-left"></i>
                            Reverse
                        </button>
                        <button class="btn btn-danger btn-sm"  v-else><span class="spinner-border spinner-border-sm "></span></button>
                </span>
            <span class="d-flex">

                <span v-if="((isAdmin == true || me.crf_users == 'ADM' ) && (status == 'approval' || status == 'PADNJOapproval') && !disapproveShow) || isAPV && !disapproveShow" class="d-flex">


                    <span>
        
                        <button class="btn btn-success btn-sm mr-1" @click="crfApproved()" v-if="!approve_loading">
                            <i class="fa-solid fa-thumbs-up"></i>
                            Approve
                        </button>

                        <button class="btn btn-success btn-sm mr-1" v-else><span class="spinner-border spinner-border-sm "></span></button>
                    </span>


                    <button class="btn btn-danger btn-sm mr-1" @click="disapproveOpen()">
                        <i class="fa-solid fa-thumbs-down"></i>
                        Disapprove
                    </button>
                </span>

                <span v-if="((isAdmin == true || me.crf_users == 'ADM' ) && (status == 'approval' || status == 'PADNJOapproval') && disapproveShow) || isAPV && disapproveShow">
                    <span>
                 
                        <button class="btn btn-danger btn-sm mr-1" @click="crfDisapproved()" v-if="!disapproved_loading">
                            <i class="fa-solid fa-thumbs-down"></i>
                            Disapprove
                        </button>

                        <button class="btn btn-danger btn-sm mr-1" v-else><span class="spinner-border spinner-border-sm"></span></button>
                    </span>
                    <button class="btn btn-secondary btn-sm " @click="disapproveOpen()">Cancel</button>
                </span>

                <span v-if="!disapproveShow">
                    
      
                    <button class="btn btn-warning btn-sm mr-1" @click="downloadCRF(selected_data)" v-if="!dl_loading">
                        <i class="fa-sharp fa-solid fa-file-pdf"></i>
                        DL PDF
                    </button>
                    <button class="btn btn-warning btn-sm mr-1" disabled v-else><span class="spinner-border spinner-border-sm"></span></button>
                </span>
            </span>
            <span v-if="status =='active' && isAdmin != true">
                    <button class="btn btn-danger btn-sm mr-1 "  @click="Cancel(selected_data)" v-if="!cancel_loading">
                            <i class="fa-solid fa-trash"></i>
                            Delete
                        </button>
                        <button class="btn btn-primary btn-sm mr-1" v-else><span class="spinner-border spinner-border-sm"></span> </button>
            </span>

                    <button class="btn btn-default btn-sm mx-1 right-bar-toggle ml-auto" id="closebtn">Close</button>
            </div>

</template>
<script>
import { mapGetters } from 'vuex'
export default {
    inject:['showFile','showUpload'],
    props: [
        'selected',
        'selected_data',
        'files'
    ],
    // data(){
    //     return{
    //         me: {}
    //     }
    // },
    // computed : {
    //     ...mapGetters([
    //         'getDetails',
    //         // ...
    //         ])
    // },
    // mounted(){
    //     this.me = this.getDetails
    // }
    data() {
        return {
        
            data: {},
            // status: this.$route.name,
            me : {},
            loading: false,
            isuploadLoading: false,
            disapproveShow :false,
            dl_loading : false,
            send_loading :false,
            cancel_loading :false,
            approve_loading : false,
            disapprove_loading : false,
            reverse_loading: false,
            status_det : '',
            filetypes: {
                'image': 'image',
                'pdf' : 'pdf',
                'text': 'text',
                'application/vnd.openxmlformats-officedocument' : 'application/vnd.openxmlformats-officedocument',
                'application/vnd.ms-excel' : 'application/vnd.ms-excel',
                'link' : 'link'
             },
            selected_files : [],
            uploads: [],
            isfileshow : false,
            showModal :false,
            ismeloading: true,
        }
    },
    mounted() {
        //   this.modalMe()
          this.me = this.getDetails

    },

    computed : {
        status(){
           
            return this.$route.name
        },
        checkApproved(){
            if(this.selected_data.approved_by == null){
                return true
            }
            else {
                return false
            }
        },
        isAdmin(){
            let response
            switch(this.me.user_access){
                case 'APV' :
                case 'PTR' : 
                    response = true
                break
                default : 
                 response = false
                break
            }

            // console.log(this.me);
            return response
        },
        isAPV(){
             let response = false

             switch(this.me.user_access){
                case 'PDAPV' :
                case 'JOAPV' : 
                case 'ADM' :
                    if(this.$route.name == 'PADNJOapproval') response = true

                    else response = false
                break
                default : 
                 response = false
                break
            }
            // console.log("CHECK");
            
            // console.log(this.me.user_access);
            // console.log(response);


            return response
        },
        SendValidation(){
                if(this.selected_data.miv_no > 0 || this.selected_data.pa_number ||  this.selected_data.dn_number ||  this.selected_data.jo_number)return true;
            
                return false;
        
        },
        ...mapGetters([
            'getDetails',
            // ...
            ])

    },
    methods: {


        open() {
              this.disapproveShow = false
            let body = document.body;
            if(body.classList.contains('ks-right-bar-open')) {
                this.data = {};
                
                body.classList.remove('ks-right-bar-open');
            }
            else body.classList.add('ks-right-bar-open');

           
        },

        async modalMe(){
             await this.API.ME()
             .then(res=> {
                this.me = res
             })
             .finally(this.ismeloading = false)
        
        },
        Edit(data){
          
            this.open()
            this.$emitter.emit('edit',data)
        },
        disapproveOpen(){
            this.data = {}
            this.disapproveShow = !this.disapproveShow ;
            
        },
        async downloadCRF(data){
            // let data = this.selected_data


            this.dl_loading = !this.dl_loading;
            await this.API.downloadCRF(data)
            .then(res => {
                    var file = res.data;
                    
                    var mime_type = file.mime_type;
                    var filename = file.name;
                    // console.log(file)
                    //FILE
                    if(mime_type != 'link') {
                        var binary = 'data:'+mime_type+';base64,'+file.binary;
                        const link = document.createElement('a');
                        link.href = binary;
                        link.setAttribute('download', filename); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }
   
                // console.log(res)
            this.dl_loading = !this.dl_loading;


            })
            .catch(err => {
                // console.log(err)
                this.$jlalert.show(err)
                this.dl_loading = !this.dl_loading;

            })

        },
        async Send(data){
            this.send_loading = !this.send_loading
        await this.API.sendCRF(data)
            .then( res => {
                this.open()
                this.$jlalert.show(res.data, res.status != 200);
                this.$emitter.emit('approved')
                this.send_loading = !this.send_loading

            })
            .catch(err => {
                this.$jlalert.show(err)
                this.send_loading = !this.send_loading

            })
        },
       async Cancel(data){
            this.cancel_loading = !this.cancel_loading
            await this.API.cancelCRF(data)
            .then( res => {
                this.open()
                this.$jlalert.show(res.data, res.status != 200);
                this.$emitter.emit('approved')
                this.cancel_loading = !this.cancel_loading

            })
            .catch(err => {
                this.$jlalert.show(err)
                this.cancel_loading = !this.cancel_loading

            })
        },
        async reverseCRF(data){
            this.reverse_loading = !this.reverse_loading
            await this.API.reverseCRF(data)
            .then( res => {
                this.open()
                this.$jlalert.show(res.data, res.status != 200);
                this.$emitter.emit('approved')
                this.reverse_loading = !this.reverse_loading

            })
            .catch(err => {
                this.$jlalert.show(err)
                this.reverse_loading = !this.reverse_loading

            })
        },

        async crfApproved(){
            this.approve_loading = !this.approve_loading
            await this.API.approveCRF(this.selected_data).
            then(res=> {
                
                    this.open()
                    this.$jlalert.show(res.data,res.status != 200)
                    this.$emitter.emit('approved')
                    this.approve_loading = !this.approve_loading

        
            })
            .catch(err => {
                    this.$jlalert.show(err)
                this.approve_loading = !this.approve_loading


            })
           
        },
        async crfDisapproved(){

            this.disapproved_loading = !this.disapproved_loading
            this.data.cr_no = this.selected_data.cr_no

            await this.API.disapproveCRF(this.data).
            then(res=> {
                    this.disapproveOpen()
                    this.open()
                    this.$jlalert.show(res.data,res.status != 200)
                    this.$emitter.emit('approved')
                    this.disapproved_loading = !this.disapproved_loading
                    
 
            })
            .catch(err => {
                this.$jlalert.show(err)
                this.disapproved_loading = !this.disapproved_loading

            })
        },
         async handleFile(e) {
            let files = e.target.files;

            // console.log(files);
            if(files.length) {
                files.filter
                for(var i = 0; i < files.length; i++) {
                    let file = files[i];
         
                    let found = false;
                    for(var key in this.filetypes) {
                        if(file.type.indexOf(key) !== -1) {
                            found = true;
                 
                        }
                    }

                    if(!found) {
                        this.$jlalert.show({message:'Selected file type not allowed'});
                        return;
                    }
                    
                    let toBase64 = await this.convert(file).catch(e => Error(e));
                    if(toBase64 instanceof Error) {
                        this.$jlalert.show(toBase64.message);
                        return;
                    }

                    
                    this.uploads.filename = file.name
                    this.uploads.filesize = parseFloat(file.size / 1000).toFixed(2)
                    this.uploads.filetype = file.type
                    this.uploads.access_dept = []
                    this.uploads.cr_no = this.selected_data.cr_no
                    this.uploads.binary = toBase64

                    // this.uploads.push({

                    //     filename: file.name,
                    //     filesize: parseFloat(file.size / 1000).toFixed(2),
                    //     filetype: file.type,
                    //     access_dept: [],
                    //     binary: toBase64,
                    //     cr_no : this.selected_data.cr_no,
                    //     error: false
                    // });

                
                }          
                this.uploadFiles()
                // console.log(this.uploads)

            }

        },
        browseFile(e) {
            this.$jlbutton.unload(e);
            let input = document.createElement('input');
            input.setAttribute('type', 'file');
            // input.setAttribute('accept', 'image/*, application/pdf');
            input.setAttribute('multiple', 'multiple');
            input.addEventListener("change", this.handleFile);
            input.click();
        },
        async uploadFiles(){
            this.isuploadLoading !=   this.isuploadLoading
            await this.API.uploadFile(this.uploads)
            .then(res => {
                // console.log(res)
                this.$jlalert.show(res.data,res.status != 200)
                setTimeout(function(){
                document.getElementById("closebtn").click();

                },1000)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(this.isuploadLoading !=   this.isuploadLoading)

        },
        convert(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
    },

}
</script>

<style scoped>
.list-group .list-group-item:hover{
    cursor: pointer;
    /* background: grey; */
}
</style>