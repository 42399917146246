<template>
    <div class="d-flex flex-direction-column w-100 h-100 ">
        <div class="m-header mb-auto bg-light p-2">
           <div class="align-items-center">
                <h3> <span style="color:red;">CRF</span> {{ selected_data.cr_no }}</h3>
           </div>
        </div>
        <div class="m-body p-2">
            <div class="card" style="width: 100%;">
                <div class="card-body">
                    <div class="card-title d-flex">
                        <i class="fa-regular fa-file mr-1"></i>
                            <h5>Files</h5>
                    </div>
                    <div class="w-100">
                        <ul class="list-group list-group-flush  overflow-auto" style="height:250px;">
                            <li class="list-group-item list-group-item-action list-group-item-info" v-for="(file,index) in allFiles " :key="index"> {{ file.name }}</li>
                        
                        </ul>
                    </div>
                </div>
            </div>
            <div class="card" style="width: 100%; height: 50%;">
                <div class="card-body">
                    <div class="card-title d-flex ">
                        <i class="fa-solid fa-paperclip mr-1"></i>
                           <h5> Attachment </h5>
                        <div class="d-flex ml-auto">
                            <button class="btn btn-info btn-sm" @click="browseFile" :disabled="isuploadLoading">
                                Add file
                            </button>
                        </div>
                    </div>
                    <div class="w-100 overflow-auto" style="height:97%">

             
                        <table class="table align-items-center table-mobile mb-0" v-if="Uploads.length">
                                        <thead>
                                            <tr>
                                                <th class="bg-light" scope="col" width="40%">File</th>
                                    
                                                <th class="bg-light" scope="col">Size</th>
            
                                                <th class="bg-light" scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody class="overflow-auto">

                                        <tr v-for="(upload, index) in Uploads" :key="index" class="text-small cp">
                                            <td data-label="File" scope="row">
                                                <div class="d-flex align-items-start">
                                                    <div class="thumbnail thumbnail-sm mr-1" style="min-width: 48px; max-width: 48px; width: 48px;">
                                                        <i class="text-primary h4" :class="upload.icon"></i>
                                                    </div>

                                                    <span class="text-body" style="word-break: break-all;">{{upload.filename}}</span>
                                                </div>
                                            </td>
                                            <td data-label="Size">{{upload.filesize}}kb</td>
                                            <td data-label="">
                                            <div class="d-inline-block"> <!--dropdown -->
                                                <button class="btn btn-sm btn-outline-danger" type="button" @click="removeFile(index)">
                                                    <i class="fa-solid fa-trash"></i>
                                                </button>
                                            </div>
                                            </td>
                                        </tr>


                                        </tbody>
                        </table>
                    </div>

                </div>
    
            </div>
        </div>
        <hr/>
        <div class="m-footer mt-auto p-2">
            <span>
                <button class="btn btn-success btn-sm " :disabled="!Uploads.length"  v-if="!isuploadLoading" @click="uploadFiles">
                    Save
                </button>
                <button class="btn btn-success btn-sm mr-1" v-else> 
                    <span class="spinner-border spinner-border-sm "> </span>
                    Uploading
                </button>
            </span>
      
            <button id="CloseUpload" class="btn btn-secondary btn-sm ml-auto" @click="closeUpload">
                Close
            </button>
        </div>
    </div>
</template>
<script>

export default{
    inject:['closeUpload','closeSidebar'],
    props:['selected_data','allFiles'],
    data(){
        return{
            Uploads : [],
            filetypes: {
                'image': 'fa-regular fa-image',
                'pdf' : 'fa-regular fa-file-pdf',
                'text': 'fa-solid fa-paperclip',
                'application/vnd.openxmlformats-officedocument':'fa-regular fa-file-lines',
                'application/vnd.ms-excel': 'fa-regular fa-file-excel',
                'link' : 'fa-solid fa-link'
            },
            isuploadLoading: false,
        }
    },
    beforeUnmount(){
        this.Uploads = []
    },
    methods : {
        removeFile(id){
            this.Uploads.splice(id,1)
            // console.log(this.Uploads) 
        },
        removeFromArray(arr,id){
            const index  = arr.findIndex((obj) => obj.filename === id)
            if(index != -1 ){
                arr.splice(index,1)
            }
        },
        async handleFile(e) {
            let files = e.target.files;
            if(files.length) {
                files.filter
                for(var i = 0; i < files.length; i++) {
                    let file = files[i];
                    let icon;
                    let found = false;
                    for(var key in this.filetypes) {
                        if(file.type.indexOf(key) !== -1) {
                            found = true;
                            icon = this.filetypes[key];

                        }
                    }

                    let filesize = parseFloat(file.size / 1000).toFixed(2);
                    if(filesize > 5000){
                        this.$jlalert.show('File size to large, max 5mb');
                    // console.log(filesize)

                        return;

                    }
          

                    if(!found) {
                        this.$jlalert.show({message:'Selected file type not allowed'});
                        return;
                    }
                    
                    let toBase64 = await this.convert(file).catch(e => Error(e));
                    if(toBase64 instanceof Error) {
                        this.$jlalert.show(toBase64.message);
                        return;
                    }
            
                    this.Uploads.push({
                        icon: icon,
                        filename: file.name,
                        filesize: filesize,
                        filetype: file.type,
                        access_dept: [],
                        binary: toBase64,
                        error: false
                    });

                
                }          
            }

        },
        browseFile(e) {
            this.$jlbutton.unload(e);
            let input = document.createElement('input');
            input.setAttribute('type', 'file');
            // input.setAttribute('accept', 'image/*, application/pdf');
            input.setAttribute('multiple', 'multiple');
            input.addEventListener("change", this.handleFile);
            input.click();
        },
        convert(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        async uploadFiles(){
            this.isuploadLoading =  true
            await this.API.uploadFile(this.Uploads,this.selected_data.cr_no)
            .then(res => {
                // console.log(res)
                this.Uploads = []
                this.$jlalert.show(res.data,res.status != 200)
             
                setTimeout(function(){
                    // window.location.reload(true)
                    document.getElementById("CloseUpload").click()

                },1000)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(this.isuploadLoading =  false)

        },
    }
}
</script>
<style  scoped>

.m-body{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.m-footer {
    display: flex;
    

}

</style>

