<template>
    <div class="ks-right-bar ks-right-bar-open" data-auto-height>
        <transition mode="out-in">
            <div class="ks-wrapper d-flex flex-direction-column scroll"  v-if="!isfileshow && !isUploadShow" key="cr_details">
                
                <file-modal-details :selected_data="selected_data" :files="files" :Me="me"></file-modal-details>


            </div>
            <div class="h-100 w-100" v-else-if="isfileshow && !isUploadShow" key="cr_files">
                <file-details :files="selected_files" > </file-details>
            </div>
            <div class="h-100 w-100" v-else-if="!isfileshow && isUploadShow"  key="cr_uploads" >
                <file-upload :selected_data="selected_data" :allFiles="files"> </file-upload>
            </div>
        </transition>
        
    </div>

</template>

<script>


import file_details from './crf_filedetails'
import file_modal_details from './crf_modal_details'
import file_upload from './crf_upload'
export default {
    props: ['selected','selected_data','files'],
    components: {
        'file-details' : file_details,
        'file-modal-details' : file_modal_details,
        'file-upload' : file_upload,
    },
    data(){
        return{
   
            isUploadShow : false,
            isfileshow : false,
            

        }
    },
    
    provide(){
        return {
            'showFile' : this.showFile,
            'closeFile': this.closefile,
            'showUpload' : this.showUpload,
            'closeUpload' : this.closeUpload,
            'closeSidebar' : this.open,
        }   
    },
    methods:{
        closeUpload(){
            this.isUploadShow = false
        },
        showUpload(){
            this.isUploadShow = true
        },
        showFile(files){
            this.isfileshow = true
            this.selected_files = files
            // console.log(files)
        },
        closefile(){
             this.selected_files = []
                this.isfileshow = false

        },
        open() {
            this.isfileshow = false
            this.isUploadShow = false
            let body = document.body;
            if(body.classList.contains('ks-right-bar-open')) {
                this.data = {};
            
                body.classList.remove('ks-right-bar-open');
            }
            else body.classList.add('ks-right-bar-open');

           
        },
        close(){
            this.$emitter.emit('approved')
        },

    },
    watch: {   
        "selected": function(n) {
            if(n === false) {
                this.data = {};
                this.isfileshow = false
                this.isUploadShow = false

                this.selected_files = []
            }
           
            this.open();

            
        },
    },
    mounted() {
        let instance = this;

        this.$emitter.on('approved', function(){
            instance.close()
        })

    },
}
</script>

<style scoped>
label {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    opacity: 0.8;
}

.dropdown .dropdown-menu {
    border: solid 1px #e6e6e6;
}

.list-group .list-group-item:hover{
    cursor: pointer;
    /* background: grey; */
}
.dropdown .dropdown-menu .dropdown-item {
    border-bottom: 1px solid #f9f9f9;
}
h4, .sub {
    width: 100%;
}

.v-enter-from {
    opacity: 0;
    transform: translateX(100px);
}

.v-enter-active{
    transition: 0.5s;
}

.v-enter-to {
    opacity: 1;
}

.v-leave-from{
    opacity: 1;
}

.v-leave-active{
    transition: 0.5s;
}

.v-leave-to{
    opacity: 0;
}

.dp-items:hover {
    background-color: antiquewhite;
}


</style>