<template>
       <div class="ks-body position-relative flex-1">
        <div class="scroll">
            <table class="ks-table table table-hover">
                <thead>
                    <th>
                        #
                    </th>
                   <th>
                        CR No.
                   </th>
                    <th>
                        Date
                   </th>
                    <th>
                        Department
                   </th>
                    <th>
                      Payee
                   </th>
                   <th>
                        Created By
                   </th>
                    <th>
                       MIAF No.
                   </th>
                   <th>
                        Amount
                   </th>

                </thead>
                <tbody v-if="loading == false && crf.result">
                    <tr v-for="(crfs,index) in crf.result" :key="index" @click="openSidebar(index)">
                        <td>
                            {{parseInt(crf.no) + index + 1}}
                        </td>
                        <td>
                            {{crfs.cr_no}}
                        </td>
                        <td>
                            {{crfs.cr_date}}
                        </td>
                        <td>
                            {{crfs.cr_dept}}
                        </td>
                        <td>
                            {{crfs.payee}}
                        </td>
                        <td>
                            {{ crfs.created_by }}
                        </td>
                        <td>
                            <span v-if="crfs.miv_no > 0">
                            {{crfs.miv_no}}

                            </span>
                        </td>
                        <td>
                             {{ crfs.currency == 'PHP' ? 'PHP' : 'USD' }} {{  crfs.amount_requested }}
                        </td>
                    </tr>
                   
                </tbody>
                <tbody v-else-if="!crf.result && !loading">
                    <tr>
                        <td colspan="8" class="text-center">
                            No data found
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="8" class="text-center">
                            <span class="spinner-border spinner-border-sm"></span>
                            Loading...
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="ks-header ks-footer" v-if="crf">
        <span class="text-bold mr-5">
            <b>{{crf.total}}</b> record(s) 
        </span>
    <span class="mx-3" >
        <ul class="pagination ">
            <li class="page-item" >
                <a class="btn btn-secondary-sm border-0" :class="(crf.page == 1 ? 'disabled': loading == true ? 'disabled'  : '')"  @click="changePage('p')">  <i class="la la-angle-double-left"></i> Prev </a>
            </li>
            <li class="page-item mx-3 d-flex flex-row">
                <!-- <a class="btn btn-secondary-sm border-0">    <b>{{crf.page}}</b> / <b>{{crf.total_page}}</b> </a> -->
                    <select :disabled="!crf.total_page && !crf.total || loading" class="form-select border-0" v-model="selected_pages" @change="changePage('s')">
                        <option :value="p" v-for="p in getpages" :key="p" > <b>{{p}} / {{crf.total_page}}</b> </option>
                        
                </select>

                
            </li>
            <li class="page-item">
                <a class="btn btn-secondary-sm border-0" :class="(crf.page >= crf.total_page ? 'disabled' : loading == true ? 'disabled' : '')"  @click="changePage('n')" >  Next  <i class="la la-angle-double-right"></i> </a>
            </li>
        </ul>
    </span>
    </div>


    <crfModal :selected="selected" :selected_data="selected_data" :files="files"/>

</template>
<script>
// import crfModal from '@/views/crf/crf_modal.vue'
import crfModal from './crf_sidebar.vue'

export default {
    
    props: ['crf','loading'],
    components: {
        crfModal,

    },

    data(){
        return {
            selected : false,
            selected_data : {},
            selected_pages : 1,
            files : [],
            showModalFile: false
        }
    },
    computed: {
         getpages() {
            let pages = [];
            if(this.crf.total_page) {
                for (let index = 1; index <= this.crf.total_page; index++) {
                    pages.push(index);
                }
            }
            return pages;
        },
        routeName(){
            return this.$route.name
        }
    },

    methods: {
        openSidebar(index) {
            this.selected = !this.selected;
            this.selected_data = this.crf.result[index]

            this.getFile()
        },
        changePage(type){

            switch(type){
                case 'p': 
                    this.$emitter.emit('prev')
                    this.selected_pages --
                    
                break
                case 'n':
                    this.$emitter.emit('next')
                    this.selected_pages ++
                break
                case 's':
                    this.$emitter.emit('select',this.selected_pages)
                break
            }

        },
        Edit(data){
            this.$emitter.emit('edit-details',data)
        },
        reload(){
               this.$emitter.emit('reload')
        },
        getFile(){
            this.API.getfile(this.selected_data)
            .then(res => {
                this.files = res.data
                // console.log(this.files)

            })
            .catch(err => {
                console.log(err)
            })
        }
    },
    mounted() {
        let instance = this;
         this.$emitter.on('edit', function(data) {
            // this.$emitter.emit('edit',data)
            instance.selected = false
            instance.Edit(data)
            
        })
        this.$emitter.on('approved', function(){
            instance.reload()
        })

    },
    watch: {
    "routeName": function (data){
        switch(data){
            case "active":
            case "approval":
            case "posting":
            case "PADNJOapproval":
                this.selected_pages = 1;

            break
                
        }
    }
   }

    

}
</script>
