<template>
  
    <div class="ks-emails ks-compact d-flex flex-direction-column" v-if="edit == false">
     <div class="ks-header overflow-auto">
            <div class="search d-flex justify-content-around">
                <div class="input-icon icon-right icon icon-lg">
                        <input id="input-group-icon-text" type="text" class="form-control" placeholder="CRF/Payee/Requestor ..." ref="search" autocomplete="off" 
                            v-model="data.crf"
                           @keyup="retrieve" @keyup.enter.prevent="retrieve" style="min-width:350px">
                        <span class="icon-addon">
                            <span class="la la-search"></span>
                        </span>
                </div>
                <div class="mc-center">
                    <button class="btn btn-primary btn-sm" @click="retrieve">
                        Search
                    </button>
                </div>
            </div>
            <div class="date-retrieve">
                    <div class="input-icon icon-right icon icon-lg">
                     <input type="date" class="form-control" v-model="data.from" @change="retrieve">

                </div>
                 <div class="d-flex justify-content-center align-items-center" >
             
                    TO

                </div>
                 <div class="input-icon icon-right icon icon-lg">
                     <input type="date" class="form-control" v-model="data.to"  @change="retrieve">
                </div>
            </div>
            <div class="buttons">
                <div class="input-icon icon-right icon icon-lg">
                   <button class="btn btn-secondary btn-sm" @click="checkdate">Refresh</button>
                </div>
            </div>
        </div>
        
        <CRFtable :crf="crf" :loading="loading"/>

  
    </div>
    <div class="ks-emails ks-compact d-flex flex-direction-column" v-else>
        <crfEdit :editDetails="editDetails"/>
    </div>

</template>

<script>

import crfEdit from '@/views/crf/crf_details'
import CRFtable from '@/views/crf/crf_table.vue'

export default {
   components: {
        CRFtable,
        crfEdit
   },
   data(){
    return{
        data:{ },
        crf: {},
        loading: false,
        edit: false,
        editDetails : {},
        search_data : 0
    }
   },
   computed: {
        routeName(){
            return this.$route.name
        }
   },
   methods: {
     async retrieve(){

   
            this.loading = !this.loading 
            this.crf =  await this.API.loadcrf(this.data)
            this.loading = !this.loading
            this.data.page = this.crf.page

        
        },
    checkdate(){
            this.data = {}
            // var next = this.API._initDate(2)
            // var prev = this.API._initDate(0)
            var prev = this.$moment().subtract(30,'day').format('YYYY-MM-DD');
            var next =  this.$moment().add(30,'day').format('YYYY-MM-DD');

            console.log(prev)
            this.data.from = prev
            
            this.data.to = next
            this.data.page = 1
            this.data.type = this.routeName
            // console.log('prev',prev);
            // console.log('next',next);

            // console.log('Nex: '+ next, ' Prev : ' + prev)
          

            this.retrieve()
    },
    search(){
   
            // if(isNaN(value)) return
            // this.data.crf = value
            this.retrieve()
    }
   },
   mounted(){
     let instance = this;
        this.$emitter.on('prev', function() {
            instance.data.page --
            instance.retrieve()
        })
        this.$emitter.on('next', function(){
            instance.data.page ++
            instance.retrieve()
        })
        this.$emitter.on('select', function(pages){
            instance.data.page = pages
            instance.retrieve()
        })
        this.$emitter.on('edit-details', function(data){
            instance.editDetails = data
            instance.edit = true
        })
        this.$emitter.on('close', function(){
            instance.edit = false
            instance.retrieve()

        })
          this.$emitter.on('reload', function(){
             instance.checkdate()
        })
    this.checkdate()
  

   },
   watch: {
        "routeName": function (data){
            switch(data){
                case "active":
                case "approval":
                case "posting":
                case "PADNJOapproval":
                    this.checkdate()

                break
                    
            }
        }
   }
}
</script>

<style scoped>
.date-retrieve{
    display: flex;
}
.search {
    max-width: 450px;
    width: 100%;
}
.date-retrieve {
    margin: 0 10px;
}
@media only screen and (max-width: 890px) {

    .ks-actions {
       display: none !important;
    }
    
}
.mc-center {
    display: flex;
    justify-content: center;
    align-items: center;
}


</style>